<template>
    <span>{{updatedServiceName}}</span>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'service-name',
    	props: {
    		servicename: String
    	},

    	computed: {
    		updatedServiceName: function() {
    			/*if (servicename && servicename.endsWith("*")) {
    				return servicename.substring(0, substring.length - 1);
    			}*/
    			if (this.servicename && this.servicename.endsWith("*")) {
    				console.log('substring...');
    				return this.servicename.substring(0, this.servicename.length - 1);
    			}
    			console.log('returning normal');
    			return this.servicename;
    		}

    	},

    	methods: {

    	}
    }
</script>